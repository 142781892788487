<template>
  <div class="p-8 pt-20">
    <h1 class="font-bold uppercase mb-4 text-2xl">Logs</h1>
    <LogsTable
      v-if="!$store.state.logsError.length"
      :logs="$store.state.logs"
    />

    <TableErrors :errors="$store.state.logsError" />
  </div>
</template>

<script>
import LogsTable from '@/components/logs/LogsTable.vue';
import TableErrors from '@/components/UI/TableErrors.vue';

export default {
  components: {
    LogsTable,
    TableErrors
  }
};
</script>
